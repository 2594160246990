.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}

.slick-prev, .slick-next {
  width: 30px;
  height: 30px;
  z-index: 1;
}

.slick-prev:before, .slick-next:before {
  font-size: 30px;
  text-shadow: 0px 0px 6px rgba(0, 0 , 0, 0.7);
}