/*============================================================================
  Grid Breakpoints and Class Names
    - Do not change the variable names
==============================================================================*/
$grid-medium: 750px;
$grid-large: 990px;
$grid-widescreen: 1400px;
$grid-gutter: 30px;
$grid-gutter-mobile: 22px;

$small: 'small';
$medium: 'medium';
$medium-down: 'medium-down';
$medium-up: 'medium-up';
$large: 'large';
$large-down: 'large-down';
$large-up: 'large-up';
$widescreen: 'widescreen';

$nblue:    #000;

$success:       $nblue !default;


$xs: 320px;
$sm: 575px;
$sm-min: 576px;
$md: 767px;
$md-min: 768px;
$lg: 991px;
$lg-min: 992px;
$xl: 1199px;
$xl-min: 1200px;
$hd: 1440px;