@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("fonts/slick.eot");
  src: url("fonts/slick.eot?#iefix") format("embedded-opentype"), url("fonts/slick.woff") format("woff"), url("fonts/slick.ttf") format("truetype"), url("fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

@font-face {
  font-family: 'icons';
  src: url("fonts/icons.eot?6vv67");
  src: url("fonts/icons.eot?6vv67#iefix") format("embedded-opentype"), url("fonts/icons.ttf?6vv67") format("truetype"), url("fonts/icons.woff?6vv67") format("woff"), url("fonts/icons.svg?6vv67#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-close:before {
  content: "\e903"; }

.icon-hamburger:before {
  content: "\e901"; }

.icon-arrow:before {
  content: "\e900"; }

.icon-home3:before {
  content: "\e902"; }

.icon-stack:before {
  content: "\e92e"; }

.icon-price-tag:before {
  content: "\e935"; }

.icon-phone:before {
  content: "\e942"; }

.icon-search:before {
  content: "\e986"; }

.icon-lock:before {
  content: "\e98f"; }

.icon-tree:before {
  content: "\e9bc"; }

.icon-info:before {
  content: "\ea0c"; }

.icon-circle-up:before {
  content: "\ea41"; }

.icon-circle-down:before {
  content: "\ea43"; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch; }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*============================================================================
  Grid Breakpoints and Class Names
    - Do not change the variable names
==============================================================================*/
.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-center {
  text-align: center; }

@media screen and (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-md-down {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .hidden-lg-down {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-mobile-load {
    display: none; } }

@media screen and (max-width: 767px) {
  /* phones */ }

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* portrait phones and tablets */ }

@media screen and (max-width: 991px) {
  /* less then desktop */ }

@media screen and (min-width: 992px) {
  /*  desktop */ }

body {
  font-family: Tahoma, Arial, helvetica, sans-serif;
  color: #383838;
  padding: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  -webkit-overflow-scrolling: touch; }

a {
  text-decoration: none;
  color: #333; }

a:hover {
  text-decoration: underline;
  color: #333; }

.container {
  margin: 0 auto;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .container {
      max-width: 560px; } }

.menu-on {
  overflow-y: hidden;
  width: 100%;
  position: fixed;
  height: calc(100% - 20px);
  -webkit-overflow-scrolling: touch; }
  .menu-on .header, .menu-on .footer, .menu-on .content {
    transform: translateX(275px);
    transition: all ease-in 300ms; }
    @media (min-width: 576px) and (max-width: 767px) {
      .menu-on .header, .menu-on .footer, .menu-on .content {
        transform: translateX(45%); } }

@media screen and (max-width: 767px) {
  .header {
    transform: translateX(0px);
    transition: all ease-in 300ms;
    background-color: #fff;
    border-bottom: 1px solid #ccc; } }

@media screen and (max-width: 767px) {
  .header.stickit {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0px;
    background-color: #fff;
    transition: all ease-in 300ms;
    z-index: 14;
    box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.3);
    right: 0; } }

@media screen and (max-width: 767px) {
  .header.nav-up {
    top: -130px; } }

@media screen and (min-width: 768px) {
  .header-nav {
    border: 1px solid #5291d2;
    border-radius: 9px 9px 0 0;
    background: #1ebced;
    /* Old browsers */
    background: linear-gradient(to bottom, #1ebced 0%, #064383 100%);
    position: relative; } }

@media screen and (min-width: 992px) {
  .header-nav:after {
    z-index: 1;
    position: absolute;
    content: "";
    bottom: 47px;
    right: auto;
    height: 8px;
    left: 20px;
    width: 240px;
    top: 0px;
    max-width: 300px;
    background: #000;
    box-shadow: 0 15px 10px #000;
    transform: rotate(3deg); } }

@media screen and (min-width: 768px) {
  .header-stickit {
    display: none; } }

@media screen and (min-width: 992px) {
  .header-logo {
    margin: 38px 0 0 25px; } }

@media screen and (min-width: 768px) {
  .header-logo {
    display: block;
    width: 200px;
    height: 70px;
    float: left;
    margin: 38px 0 0 25px;
    z-index: 10;
    position: relative; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .header-logo {
    margin: 20px 0 0 10px; } }

@media screen and (max-width: 767px) {
  .header-logo {
    display: inline-block;
    margin: 4px 10px 15px 65px; }
    .header-logo img {
      height: 40px; } }

@media screen and (max-width: 991px) {
  .header-top {
    position: relative; } }

@media screen and (max-width: 767px) {
  .header-top {
    background-color: white; } }

.header-banners {
  float: right;
  padding: 13px 90px 12px 15px; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .header-banners {
      padding: 13px 0px 12px 15px; } }

@media screen and (min-width: 992px) {
  .header-logo-shadow {
    width: 270px;
    height: 50px;
    background: #FFF;
    z-index: 2;
    position: absolute;
    top: -29px;
    left: -5px; } }

@media screen and (max-width: 767px) {
  .header-logo-shadow {
    display: none; } }

@media screen and (max-width: 767px) {
  .header-breadcrumbs {
    display: none;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.05);
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0.1rem 0 rgba(0, 0, 0, 0.03) inset, 0 0.1rem 0 rgba(255, 255, 255, 0.3); } }

@media screen and (max-width: 767px) {
  .mobile-menu-toggle {
    position: absolute;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    padding-top: 3px;
    text-align: center;
    font-size: 24px;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    .mobile-menu-toggle:before {
      content: "\e901"; } }

@media screen and (max-width: 767px) {
  .mobile-search-toggle {
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    padding-top: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    .mobile-search-toggle:before {
      content: "\e986"; } }

@media screen and (min-width: 768px) {
  .mobile-search-toggle {
    display: none; } }

.footer {
  margin-top: 4px;
  background-color: #EEF3F6;
  padding: 20px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .footer {
      border-top: 1px solid #ccc;
      background-color: #FFF;
      transform: translate(0px);
      transition: translate ease-in 300ms;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 12px 0px 12px;
      padding: 12px 0px calc(env(safe-area-inset-bottom) + 12px);
      -webkit-transform: translateZ(0); } }
  @media screen and (min-width: 768px) {
    .footer:before {
      width: 100%;
      height: 4px;
      background-color: #d9d9d9;
      content: ' ';
      display: block;
      position: absolute;
      top: -8px;
      left: 0px;
      right: 0px; } }

.footer-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  @media screen and (max-width: 767px) {
    .footer-links ul {
      display: flex;
      justify-content: space-around; } }

@media screen and (min-width: 768px) {
  .footer-links li {
    display: inline-block;
    font-size: 16px;
    position: relative;
    padding: 0 15px;
    margin-left: -5px;
    line-height: 0; } }

@media screen and (max-width: 991px) {
  .footer-links li {
    font-size: 14px; } }

@media screen and (max-width: 767px) {
  .footer-links li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    font-size: 10px;
    flex: 0 1 25%;
    text-align: center;
    padding: 0 6px; } }

@media screen and (min-width: 768px) {
  .footer-links li:after {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    background-color: #302C26;
    position: absolute;
    right: 0;
    top: 0; } }

@media screen and (min-width: 768px) {
  .footer-links li:last-child:after {
    display: none; } }

@media screen and (min-width: 768px) {
  .footer-links li:first-child {
    margin-left: 0px; } }

@media screen and (min-width: 768px) {
  .footer-links li a {
    line-height: normal; } }

@media screen and (max-width: 767px) {
  .footer-links li a {
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%; } }

@media screen and (max-width: 767px) {
  .footer-links li a:hover, .footer-links li a:active {
    text-decoration: none; } }

@media screen and (max-width: 767px) {
  .footer-links li.active a {
    color: #29aae1; } }

@media screen and (max-width: 767px) {
  .footer-links li.active .f-link:before {
    color: #29aae1; } }

.f-link:before {
  display: inline-block;
  font-size: 22px;
  margin-bottom: 6px;
  position: relative;
  color: #505052;
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media screen and (max-width: 767px) {
  .f-link--about:before {
    content: "\ea0c"; }
  .f-link--categories:before {
    content: "\e92e"; }
  .f-link--stores:before {
    content: "\e935";
    font-size: 20px;
    top: 1px; }
  .f-link--home:before {
    content: "\e902"; } }

.footer-copyright {
  margin-top: 15px; }
  @media screen and (max-width: 991px) {
    .footer-copyright {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    .footer-copyright {
      display: none; } }

.footer-privacy {
  position: relative;
  margin-left: 15px;
  padding-left: 16px; }
  .footer-privacy:before {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    background-color: #302C26;
    position: absolute;
    left: 0px;
    top: 0; }

.content {
  padding-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .content {
      transform: translateX(0px);
      transition: all ease-in 300ms;
      padding-bottom: 75px; } }
  @media screen and (min-width: 768px) {
    .content {
      display: flex;
      width: 100%; } }

@media screen and (max-width: 767px) {
  .content--store_detail {
    padding-bottom: 95px; } }

.content--store_detail .content-right {
  display: none; }
  @media screen and (min-width: 992px) {
    .content--store_detail .content-right {
      display: none; } }
  @media screen and (max-width: 767px) {
    .content--store_detail .content-right {
      display: none; } }

@media screen and (max-width: 767px) {
  .content--contact .content-right, .content--about .content-right, .content--privacy .content-right {
    display: none; } }

@media screen and (max-width: 767px) {
  .content--category, .content--all-stores {
    padding-bottom: 95px; } }

.content-title {
  display: block;
  border-bottom: 2px solid #6D90AB;
  font-size: 1.625em;
  letter-spacing: -1px;
  color: #6D90AB;
  font-weight: normal;
  padding: 0 20px;
  margin: 10px 0 25px; }

.content-text {
  padding: 10px 20px 30px; }
  @media screen and (max-width: 767px) {
    .content-text {
      padding: 10px 15px 30px; } }
  .content-text h3 {
    color: #589ac1;
    font-weight: bold; }
  .content-text a {
    color: #589ac1;
    font-weight: bold;
    text-decoration: underline; }
  .content-text a:hover {
    text-decoration: none; }

.content-left {
  width: 220px; }
  @media screen and (min-width: 992px) {
    .content-left {
      flex: 0 0 220px;
      margin-right: 10px; } }
  @media screen and (max-width: 991px) {
    .content-left {
      display: none; } }

@media screen and (min-width: 768px) {
  .content-main {
    flex: 1 1 auto; } }

@media screen and (max-width: 767px) {
  .content-main {
    padding-top: 15px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .content-main--index {
    max-width: calc(100% - 200px); } }

@media screen and (max-width: 767px) {
  .content-main--index {
    width: 100%; } }

.content-main--stores {
  width: 100%; }
  @media screen and (max-width: 767px) {
    .content-main--stores {
      padding: 0 5px; } }

.content-main--category {
  width: 100%; }
  @media screen and (max-width: 767px) {
    .content-main--category {
      padding: 0 5px; } }

.content-right {
  width: 190px; }
  @media screen and (min-width: 768px) {
    .content-right {
      flex: 0 0 190px;
      margin-left: 10px; } }
  @media screen and (max-width: 767px) {
    .content-right {
      width: 100%;
      margin-bottom: 10px; } }

.index-banner img {
  width: 100%; }

@media screen and (min-width: 992px) {
  .index-banner:nth-child(2) {
    margin-top: 10px; } }

.index-main-text {
  margin-top: 20px;
  padding-bottom: 25px; }
  @media screen and (max-width: 575px) {
    .index-main-text {
      padding-left: 15px;
      padding-right: 15px; } }
  .index-main-text p {
    font-size: 14px; }
  .index-main-text img {
    width: 100%; }

.index-promo {
  margin-top: 10px; }

.details-banner {
  width: 100%;
  padding: 15px 0 5px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .details-banner {
      padding: 15px 10px 5px; } }
  .details-banner img {
    max-width: 100%; }

.details-title {
  display: block;
  border-bottom: 2px solid #6D90AB;
  font-size: 1.625em;
  letter-spacing: -1px;
  color: #6D90AB;
  font-weight: normal;
  padding: 0 8px 0 8px;
  margin: 15px 0 25px; }
  @media screen and (max-width: 767px) {
    .details-title {
      margin: 15px -5px 14px;
      padding: 0 8px 0 15px;
      font-size: 1.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }

.details-info {
  border: 1px solid #cdcdcd;
  margin: 10px 0 30px; }
  @media screen and (max-width: 767px) {
    .details-info {
      margin: 10px 0 30px; } }
  .details-info h2 {
    font-size: 18px;
    font-weight: normal;
    border-bottom: 2px solid #6D90AB;
    padding: 8px;
    color: #6D90AB;
    margin-bottom: 0; }

.details-info-content {
  display: flex;
  padding: 15px 20px 15px 20px; }
  @media screen and (max-width: 767px) {
    .details-info-content {
      padding: 12px 8px; } }

.details-info-links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .details-info-links {
      margin-right: 8px; } }
  .details-info-links .btn {
    align-self: center;
    margin-bottom: 2px; }

.details-info-logo {
  margin-bottom: 10px;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .details-info-logo img {
      width: 120px; } }

.details-info-text {
  color: #68707b;
  font-size: 0.75em;
  line-height: 1.25em;
  padding-top: 2px; }

.detail-coupon {
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .detail-coupon {
      margin-bottom: 20px; } }

.detail-coupon-header {
  background-color: #dde7ed;
  padding: 5px 10px 6px 14px;
  border-radius: 10px 10px 0 0; }

.detail-coupon-title {
  color: #010101;
  font-weight: 700;
  font-size: 14px;
  margin: 0; }
  .detail-coupon-title a {
    text-decoration: underline; }
  .detail-coupon-title a:hover {
    text-decoration: none; }

.detail-coupon-content {
  color: #68707b;
  font-size: 0.875em;
  line-height: 1.25em;
  padding: 12px 20px 12px 14px; }
  @media screen and (max-width: 767px) {
    .detail-coupon-content {
      padding: 12px 10px; } }

.detail-coupon-opt {
  display: flex;
  justify-content: space-between;
  margin-top: 8px; }

.detail-coupon-code {
  margin-right: 10px;
  font-size: 0.75em; }

.detail-coupon-button {
  display: flex;
  align-items: flex-end;
  margin-right: 8px; }

.detail-coupon-code span {
  display: block; }

.store-list {
  border: 1px solid #c3c3c1; }
  .store-list table {
    width: 100%; }
    .store-list table tr:nth-child(odd) {
      background: #EEF3F6; }
    .store-list table td {
      padding: 20px 0;
      border-bottom: 1px solid #c3c3c1;
      width: 33.33%; }
    .store-list table .name {
      width: 160px;
      padding: 0 0 0 0px;
      font-size: 1.3em;
      text-align: center; }
      @media screen and (max-width: 767px) {
        .store-list table .name {
          padding: 0 0 0 15px; } }
      .store-list table .name img {
        max-width: 150px;
        width: 100%; }
    .store-list table .description {
      text-align: center;
      text-transform: uppercase; }
    .store-list table .opis {
      text-align: center;
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      color: #686868; }
    .store-list table .opis a {
      color: #686868;
      text-decoration: underline; }
    .store-list table .opis a:hover {
      text-decoration: none; }
    .store-list table .button {
      text-align: center; }

.gnotice {
  position: fixed;
  display: block;
  width: 100%;
  background: #393939;
  font-size: 14px;
  color: black;
  z-index: 2147000001;
  border: 0px solid white;
  bottom: 0px;
  left: 0px; }
  .gnotice a {
    color: #afe3f8;
    text-decoration: underline; }

.gnotice-accept {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 5px 12px;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.5s ease 0s; }
  @media screen and (max-width: 767px) {
    .gnotice-accept {
      right: 10px; } }

.gnotice-accept-svg {
  fill: white; }

.gnotice-accept:hover .gnotice-accept-svg {
  fill: silver; }

.gnotice-message {
  padding: 20px 120px 20px 120px;
  color: white;
  line-height: 1.75; }
  @media screen and (max-width: 767px) {
    .gnotice-message {
      padding: 20px 60px 20px 20px; } }

@media screen and (min-width: 992px) {
  .nav {
    padding: 10px 296px 12px 270px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .nav {
    padding: 10px 296px 12px 0px; } }

@media screen and (max-width: 767px) {
  .nav {
    background-color: #eef3f6;
    border-right: 1px solid #c3c3c5;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transform: translateX(-275px);
    visibility: hidden;
    min-height: 100vh;
    min-height: calc(100vh - env(safe-area-inset-bottom));
    height: auto;
    max-width: 275px;
    width: 275px;
    z-index: -1;
    padding: 40px 23px 0px 23px;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    transition: all ease-in 0.3s; }
    @supports (-webkit-overflow-scrolling: touch) {
      .nav {
        padding-bottom: 120px; } }
    .nav.is-on {
      transition: all ease-in 0.3s;
      visibility: visible;
      opacity: 1;
      z-index: 100; } }

@media (min-width: 576px) and (max-width: 767px) {
  .nav {
    width: calc((100vw - 100%)/2 + 45%);
    transform: translateX(-100%);
    max-width: none; } }

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  @media screen and (min-width: 768px) {
    .nav ul {
      display: flex; } }
  @media screen and (max-width: 767px) {
    .nav ul {
      padding-bottom: 15px; } }

@media screen and (min-width: 768px) {
  .nav ul li {
    display: inline-block;
    padding: 0 4px;
    flex: 1 1 auto;
    text-align: center;
    overflow: hidden;
    color: white;
    border-right: 1px solid #fff; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .nav ul li {
    font-size: 14px; } }

@media screen and (max-width: 767px) {
  .nav ul li {
    border-bottom: 1px dotted #acaeaf;
    line-height: 20px;
    padding: 10px 0;
    font-size: 18px; } }

.nav ul li:last-child {
  border-bottom: none; }

.nav ul li.nav-category.is-collapsed {
  padding-bottom: 0px; }

@media screen and (min-width: 768px) {
  .nav ul li.active a {
    color: #2a2a2a; } }

@media screen and (max-width: 767px) {
  .nav ul li.active a {
    color: #29aae1; } }

@media screen and (max-width: 767px) {
  .nav ul li.active .nav-icon:before {
    color: #29aae1; } }

@media screen and (min-width: 768px) {
  .nav ul li a {
    color: white; } }

@media screen and (max-width: 767px) {
  .nav ul li a {
    color: #505052; } }

@media screen and (max-width: 767px) {
  .nav ul li a:hover {
    text-decoration: none;
    color: #505052; } }

@media screen and (max-width: 767px) {
  .nav-icon:before {
    font-size: 14px;
    margin-right: 11px;
    position: relative;
    top: -1px;
    color: #505052;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .nav-contact:before {
    content: "\e942"; }
  .nav-sitemap:before {
    content: "\e9bc"; }
  .nav-privacy:before {
    content: "\e98f";
    left: 2px; }
  .nav-about:before {
    content: "\ea0c"; }
  .nav-categories:before {
    content: "\e92e"; }
  .nav-stores:before {
    content: "\e935"; }
  .nav-home:before {
    content: "\e902"; } }

.nav-search {
  /* visibility: hidden;
    opacity: 0;
    transform: translateY(-50px);
    height: 0;
    transition: all ease-in-out 0.3s;
    z-index: -1;
  }

  &.is-on {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    height: auto;
    transition: all ease-in-out 0.3s;
  }*/ }
  @media screen and (min-width: 768px) {
    .nav-search {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      padding-bottom: 2px;
      font-size: 13px;
      color: white; } }
  @media screen and (max-width: 767px) {
    .nav-search {
      display: none;
      text-align: right;
      width: 100%;
      padding: 0px 15px 0px 15px;
      animation-duration: 0.7s;
      animation-iteration-count: 1; } }
  .nav-search.is-on {
    display: flex;
    justify-content: flex-end; }

.nav-search-form {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 320px;
  margin-left: auto;
  padding: 0px 0px 15px 0px; }

.nav-search-input {
  font-size: 12px;
  border: 1px solid #C7C7C7;
  padding: 2px 2px 2px 6px;
  display: inline-block;
  height: 24px;
  vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .nav-search-input {
      flex: 1 1 auto;
      margin: 0 5px;
      height: 28px; } }

.nav-sub-categories {
  display: none; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .nav-sub-categories {
      position: absolute;
      background-color: white;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border: 1px solid #5291d2;
      border-radius: 0 0 9px 9px;
      margin-left: -1px;
      margin-right: -1px;
      flex-direction: row;
      text-align: left;
      justify-content: space-around;
      padding: 10px 5px;
      transition: all ease-in 0.3s; } }
  @media screen and (max-width: 767px) {
    .nav-sub-categories {
      padding: 0px 20px; } }
  @media screen and (max-width: 767px) {
    .nav-sub-categories span {
      display: block;
      font-size: 14px;
      border-bottom: 1px dotted #acaeaf;
      padding: 3px 0 3px 12px;
      overflow: hidden;
      text-overflow: ellipsis; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .nav-sub-categories span {
      display: block;
      padding: 4px 0; } }
  @media screen and (max-width: 767px) {
    .nav-sub-categories span:before {
      font-size: 6px;
      display: inline-block;
      font-family: 'icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e900";
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      left: -6px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .nav-sub-categories span a {
      color: black !important; } }
  .nav-sub-categories span.active a {
    color: #29aae1; }
  @media screen and (max-width: 767px) {
    .nav-sub-categories div:first-child span:first-child {
      padding-top: 9px; } }
  @media screen and (max-width: 767px) {
    .nav-sub-categories div:last-child span:last-child {
      border-bottom: none;
      padding-bottom: 5px; } }

@media screen and (max-width: 767px) {
  .nav-category {
    position: relative; } }

@media screen and (max-width: 767px) {
  .nav-category:after {
    font-size: 18px;
    display: inline-block;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\ea41";
    display: inline-block;
    position: absolute;
    top: 11px;
    right: 0;
    transition: all ease-in-out 0.3s;
    transform: rotate(180deg); } }

.nav-category.is-collapsed:after {
  transform: rotate(0deg);
  transition: all ease-in-out 0.3s;
  top: 12px;
  color: #29aae1; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .nav-category.is-collapsed .nav-sub-categories {
    display: flex;
    top: 43px;
    transition: all ease-in 0.3s; } }

@media screen and (max-width: 767px) {
  .nav-category.is-collapsed .nav-sub-categories {
    display: block;
    transition: all ease-in 0.3s; } }

.nav ul li.nav-category.active > * a {
  color: #505052; }

.nav ul li.nav-category.active > * .active a {
  color: #29aae1; }

@media screen and (max-width: 767px) {
  .nav ul li.nav-category.active:after {
    transform: rotate(0deg);
    top: 12px;
    color: #29aae1; } }

@media screen and (max-width: 767px) {
  .nav ul li.nav-category.active .nav-sub-categories {
    display: block; } }

.nav-close {
  position: absolute;
  right: 0px;
  top: 14px;
  font-size: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s; }
  .nav-close.is-visible {
    opacity: 1;
    right: 20px;
    visibility: visible;
    transition: all ease-in-out 0.3s; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

.animated.delay-250s {
  -webkit-animation-delay: .25s;
  animation-delay: .25s; }

.animated.quick {
  -webkit-animation-duration: 450ms;
  animation-duration: 450ms; }

.box {
  background-color: #EEF3F6;
  border: 1px solid #c3c3c5;
  margin-bottom: 15px;
  padding: 1px; }
  @media screen and (min-width: 768px) {
    .box--left {
      border-top-left-radius: 10px; } }
  @media screen and (min-width: 768px) {
    .box--left .box-header {
      border-top-left-radius: 10px; } }
  @media screen and (min-width: 768px) {
    .box--right {
      border-top-right-radius: 10px; } }
  @media screen and (min-width: 768px) {
    .box--right .box-header {
      border-top-right-radius: 10px; } }
  .box:last-child {
    margin-bottom: 0; }

.box-header {
  background: #2a2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(top, #545456 0%, #2a2a2a 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #545456 0%, #2a2a2a 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #545456 0%, #2a2a2a 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#545456', endColorstr='#2a2a2a',GradientType=0 );
  /* IE6-9 */
  border: 1px solid #2a2a2a;
  padding: 6px 5px 8px 20px; }

.box-title {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  font-weight: normal; }

@media screen and (max-width: 767px) {
  .box-side-item {
    margin-right: 15px;
    -webkit-overflow-scrolling: touch; } }

@media screen and (min-width: 768px) {
  .box-side-item {
    margin-bottom: 10px; } }

.box-side-item:last-child {
  margin-bottom: 0px; }

@media screen and (max-width: 767px) {
  .box-side-item img {
    max-width: 100%;
    width: 100%; } }

.box-list {
  list-style-type: none;
  margin: 0;
  padding: 4px 10px 4px 22px;
  border-top: 4px solid #d9d9d9; }
  .box-list li {
    color: #000;
    border-bottom: 1px dotted #acaeaf;
    line-height: 20px;
    padding: 8px 0;
    font-size: 14px;
    word-break: break-all;
    text-indent: -3px; }
    .box-list li:last-child {
      border-bottom: none; }
    .box-list li:before {
      font-size: 6px;
      display: inline-block;
      font-family: 'icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e900";
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      left: -6px; }

.box-content-top {
  padding: 5px 5px 0 5px;
  border-top: 4px solid #d9d9d9; }

.box-top-coupons {
  padding: 8px 5px 8px 5px;
  font-family: Serif;
  background: #DDE7ED;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fefefe 0%, #dee7ee 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fefefe 0%, #dee7ee 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fefefe 0%, #dee7ee 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#dee7ee',GradientType=0 );
  /* IE6-9 */
  margin-bottom: 10px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .box-top-coupons {
      padding: 8px 15px; } }
  .box-top-coupons:after {
    border-bottom: 1px dotted #acaeaf;
    display: block;
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0; }

.box-top-coupon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .box-top-coupon a {
    color: #2A2A2B;
    text-decoration: underline;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .box-top-coupon a {
        font-size: 18px; } }
  .box-top-coupon a:hover {
    text-decoration: none; }
  .box-top-coupon .icon {
    font-size: 6px;
    position: relative;
    top: -1px;
    margin-right: 5px; }

.box-top-label {
  color: #6D90AB; }
  @media screen and (max-width: 767px) {
    .box-top-label {
      font-size: 18px; } }

.box-side--coupons {
  padding: 0;
  -webkit-overflow-scrolling: touch; }
  .box-side--coupons.slick-initialized .slick-slide {
    margin-right: 10px; }

.blue-header {
  border-top: 1px solid #5190d5;
  background: #1ebced;
  /* Old browsers */
  background: linear-gradient(to bottom, #1ebced 0%, #064383 100%);
  border-bottom: 4px solid #d9d9d9;
  margin-top: 15px; }

.blue-header-title {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 5px 20px 8px 20px; }
  @media screen and (max-width: 767px) {
    .blue-header-title {
      padding: 7px 20px 9px 20px; } }

.promo-box {
  border-bottom: 1px dashed #acaeaf;
  margin-bottom: 7px;
  display: flex;
  padding: 8px 25px 12px 4px; }
  @media screen and (max-width: 767px) {
    .promo-box {
      padding: 8px 15px 12px 15px; } }
  .promo-box:last-child {
    margin-bottom: 15px; }
  .promo-box-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px; }
    @media screen and (min-width: 768px) {
      .promo-box-logo img {
        width: 150px; } }
    @media screen and (max-width: 767px) {
      .promo-box-logo img {
        width: 100px; } }

.promo-box-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }
  .promo-box-content h4 {
    font-size: 16px;
    margin-bottom: 0.25rem;
    line-height: 1.25em; }
  .promo-box-content p {
    font-size: 14px;
    margin-bottom: 0.5rem; }
  .promo-box-content .btn {
    align-self: flex-end; }

.top-coupons-container {
  margin-bottom: 20px;
  display: block; }
  @media screen and (max-width: 767px) {
    .top-coupons-container {
      margin-left: -5px;
      margin-right: -5px;
      background-color: rgba(0, 0, 0, 0.03);
      border-top: 0.1rem solid rgba(0, 0, 0, 0.05);
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden; } }
  @media screen and (max-width: 767px) {
    .top-coupons-container:after, .top-coupons-container:before {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 20px;
      text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
      transition: all ease-in 300ms;
      font-family: 'slick';
      font-size: 40px;
      line-height: 1;
      color: white;
      -webkit-font-smoothing: antialiased;
      z-index: 10;
      opacity: 0;
      visibility: hidden; }
    .top-coupons-container:before {
      left: -17px;
      content: '←'; }
    .top-coupons-container:after {
      right: -17px;
      content: '→'; }
    .top-coupons-container.scrollable:after {
      opacity: .75;
      visibility: visible; }
    .top-coupons-container.scrolling:after, .top-coupons-container.scrolling:before {
      opacity: 0;
      visibility: hidden; }
    .top-coupons-container.scrolled-right:before {
      opacity: .75;
      visibility: visible; }
    .top-coupons-container.scrolled-right:after {
      opacity: 0;
      visibility: hidden; } }
  .top-coupons-container .top-coupons-list {
    display: flex;
    justify-content: flex-start;
    padding: 10px 15px; }
    @media screen and (max-width: 767px) {
      .top-coupons-container .top-coupons-list {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        justify-content: flex-start; } }
    @media screen and (min-width: 768px) {
      .top-coupons-container .top-coupons-list {
        width: 100%; } }

.top-coupons-box {
  min-width: 220px;
  margin: 0 5px;
  flex: 0 1 33%;
  border: 1px solid #e3e3e3;
  padding: 2px;
  display: flex;
  color: #6D90AB; }

@media screen and (min-width: 768px) {
  .top-coupons-box:first-child {
    margin-left: 10px; } }

@media screen and (min-width: 768px) {
  .top-coupons-box:last-child {
    margin-right: 10px; } }

@media screen and (max-width: 767px) {
  .top-coupons-box:last-child {
    position: relative; }
    .top-coupons-box:last-child:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      width: 20px;
      height: 1px; } }

.top-coupons-box-inner span {
  display: block; }

.top-coupons-box-inner {
  background: #DDE7ED;
  /* Old browsers */
  background: linear-gradient(to bottom, #ffffff 0%, #f9fafc 30%, #eef3f7 50%, #dee7ee 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px 8px 8px 9px; }
  .top-coupons-box-inner span {
    display: block;
    font-size: 0.75em;
    padding-left: 8px; }

.top-coupons-button {
  text-align: center;
  margin: 10px 0; }

.top-coupons-detail {
  border-top: 1px dotted #6D90AB; }

.top-coupons-content {
  padding-bottom: 20px;
  font-size: 0.875em; }
  .top-coupons-content p {
    margin-top: 6px; }
  .top-coupons-content a {
    text-decoration: underline; }
  .top-coupons-content a:hover {
    text-decoration: none; }

.top-coupons-title {
  padding: 0 0 0 6px; }
  .top-coupons-title:before {
    font-size: 6px;
    font-family: icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e900";
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    left: -6px; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.page-crumbs {
  line-height: 35px;
  font-size: 12px;
  color: #68707b;
  margin: 10px 0px 10px 0; }

.page-link {
  margin: 0 4px 0 0;
  display: inline-block;
  text-align: center;
  border: 1px solid #cdcdcd; }
  @media screen and (min-width: 768px) {
    .page-link {
      border: 1px solid #cdcdcd; } }
  @media screen and (max-width: 767px) {
    .page-link {
      font-size: 14px;
      flex: 1 1 auto;
      max-width: 45px; } }
  .page-link a {
    height: 28px;
    line-height: 24px;
    display: block;
    text-decoration: none;
    padding: 2px 4px;
    min-width: 28px; }
    @media screen and (max-width: 767px) {
      .page-link a {
        font-size: 14px;
        padding: 2px 2px; } }
    @media screen and (min-width: 992px) {
      .page-link a {
        height: 24px;
        line-height: 20px;
        min-width: 24px; } }
  .page-link:last-child {
    margin-right: 0; }

.page-current {
  line-height: 24px;
  padding: 2px 4px;
  min-width: 28px;
  border: none; }

.page-number {
  background: #eeeeee; }

.page-label a {
  padding: 2px 6px; }
  @media screen and (max-width: 767px) {
    .page-label a {
      padding: 2px 2px; } }

.btn.page-go {
  height: 30px;
  line-height: 27px;
  vertical-align: bottom;
  padding: 0 8px;
  border-radius: 5px; }

.page-number:hover {
  background: #ffffff; }

.page-NumberForm {
  display: inline-block;
  line-height: 30px;
  flex: 0 0 auto;
  margin: 0 5px 0 0; }
  @media screen and (min-width: 768px) {
    .page-NumberForm {
      display: none; } }
  .page-NumberForm input {
    width: 35px;
    height: 30px;
    border: 1px solid #29aae1;
    text-align: center;
    padding: 0 2px;
    line-height: 20px;
    border-radius: 0;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.3); }

.page-small {
  text-align: right; }
  @media screen and (max-width: 767px) {
    .page-small {
      margin-right: 15px; }
      .page-small .page-NumberForm {
        display: none; }
      .page-small .page-label {
        padding: 0 4px; } }

@media screen and (max-width: 767px) {
  .page-large {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0px 15px 0; }
    .page-large .page-number {
      display: none; }
    .page-large .page-current {
      display: none; } }

@media screen and (min-width: 768px) {
  .page-large {
    text-align: right; } }

.btn {
  border: 1px solid #ababab;
  border-radius: 6px;
  display: inline-block;
  height: 28px;
  line-height: 25px;
  text-transform: lowercase;
  background: #eceded;
  /* Old browsers */
  background: linear-gradient(to bottom, #eceded 0%, #c7c9c9 100%);
  font-size: 12px;
  padding: 0px 14px;
  font-weight: bold;
  position: relative; }
  .btn:hover {
    text-decoration: none;
    border: 1px solid #ccc;
    transition: all ease-in-out 150ms;
    color: #6d90ab; }
  .btn:disabled {
    opacity: 0.6; }
  .btn--blue {
    border: 1px solid #5291d2;
    background: #2ea4e5;
    /* Old browsers */
    background: linear-gradient(to bottom, #83e2f7 0%, #2ea4e5 100%); }
    .btn--blue:hover {
      text-decoration: none;
      border: 1px solid #2ea4e5;
      color: #fff; }

.slick-prev {
  left: 20px; }

.slick-next {
  right: 20px; }

.slick-prev, .slick-next {
  width: 30px;
  height: 30px;
  z-index: 1; }

.slick-prev:before, .slick-next:before {
  font-size: 30px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7); }
