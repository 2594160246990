.nav {
  @media screen and (min-width: 992px) {
    padding: 10px 296px 12px 270px
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 10px 296px 12px 0px
  }

  @media screen and (max-width: 767px){
    background-color: #eef3f6;
    border-right: 1px solid #c3c3c5;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transform: translateX(-275px);
    visibility: hidden;
    min-height: 100vh;
    min-height: calc(100vh - env(safe-area-inset-bottom));
    height: auto;
    max-width: 275px;
    width: 275px;
    z-index: -1;
    padding: 40px 23px 0px 23px;
    overflow-y:scroll!important;
    -webkit-overflow-scrolling: touch;
    transition: all ease-in 0.3s;

    @supports (-webkit-overflow-scrolling: touch) {
      padding-bottom: 120px;
    }

    &.is-on {
      transition: all ease-in 0.3s;
      visibility: visible;
      opacity: 1;
      z-index: 100;
    }
  }
  @media (min-width: $sm-min) and (max-width: $md){
    width: calc((100vw - 100%)/2 + 45%);
    transform: translateX(-100%);
    max-width: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media screen and (min-width: 768px){
      display: flex;
    }
    @media screen and (max-width: 767px){
      padding-bottom: 15px;
    }
  }

  ul li {
    @media screen and (min-width: 768px){
      display: inline-block;
      padding: 0 4px;
      flex: 1 1 auto;
      text-align: center;
      overflow: hidden;
      color: white;
      border-right: 1px solid #fff;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 14px;
    }

    @media screen and (max-width: 767px){
      border-bottom: 1px dotted #acaeaf;
      line-height: 20px;
      padding: 10px 0;
      font-size: 18px;
    }
    &:last-child {
      border-bottom: none;
    }
    &.nav-category.is-collapsed {
      padding-bottom: 0px;
    }
  }

  ul li.active a {
    @media screen and (min-width: 768px){
      color: #2a2a2a;
    }
    @media screen and (max-width: 767px){
      color: #29aae1;
    }
  }
  ul li.active .nav-icon:before {
    @media screen and (max-width: 767px){
      color: #29aae1;
    }
  }

  ul li a {
    @media screen and (min-width: 768px){
      color: white;
    }
    @media screen and (max-width: 767px){
      color:#505052;
    }
  }
  ul li a:hover {
    @media screen and (max-width: 767px){
      text-decoration: none;
      color:#505052;
    }
  }
}

@media screen and (max-width: 767px){
  .nav-icon {
    &:before {
      font-size: 14px;
      margin-right: 11px;
      position: relative;
      top: -1px;
      color: #505052;

      font-family: 'icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .nav-contact:before {
    content: "\e942";
  }
  .nav-sitemap:before {
    content: "\e9bc";
  }
  .nav-privacy:before {
    content: "\e98f";
    left: 2px;
  }
  .nav-about:before {
    content: "\ea0c";
  }
  .nav-categories:before {
    content: "\e92e";
  }
  .nav-stores:before {
    content: "\e935";
  }
  .nav-home:before {
    content: "\e902";
  }
}

.nav-search {
  @media screen and (min-width: 768px){
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding-bottom: 2px;
    font-size: 13px;
    color: white;
  }
  @media screen and (max-width: 767px){
    display: none;
    text-align: right;
    width: 100%;
    padding: 0px 15px 0px 15px;
    animation-duration: 0.7s;
    animation-iteration-count: 1;
  }
  &.is-on {
    display: flex;
    justify-content: flex-end;

  }

   /* visibility: hidden;
    opacity: 0;
    transform: translateY(-50px);
    height: 0;
    transition: all ease-in-out 0.3s;
    z-index: -1;
  }

  &.is-on {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    height: auto;
    transition: all ease-in-out 0.3s;
  }*/

}
.nav-search-form {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 320px;
  margin-left: auto;
  padding: 0px 0px 15px 0px;
}
.nav-search-input {
  font-size: 12px;
  border: 1px solid #C7C7C7;
  padding: 2px 2px 2px 6px;
  display: inline-block;
  height: 24px;
  vertical-align: middle;

  @media screen and (max-width: 767px){
    flex: 1 1 auto;
    margin: 0 5px;
    height: 28px;
  }

}

.nav-sub-categories {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 991px){
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border: 1px solid #5291d2;
    border-radius: 0 0 9px 9px;
    margin-left: -1px;
    margin-right: -1px;
    flex-direction: row;
    text-align: left;
    justify-content: space-around;
    padding: 10px 5px;
    transition: all ease-in 0.3s;
  }

  @media screen and (max-width: 767px){
    padding: 0px 20px;
  }

  span {
    @media screen and (max-width: 767px){
      display: block;
      font-size: 14px;
      border-bottom: 1px dotted #acaeaf;
      padding: 3px 0 3px 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (min-width: 768px) and (max-width: 991px){
      display: block;
      padding: 4px 0;
    }

    &:before {
      @media screen and (max-width: 767px){
        font-size: 6px;
        display: inline-block;
        font-family: 'icons' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e900";
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;
        left: -6px;
      }
    }

    a {
      @media screen and (min-width: 768px) and (max-width: 991px){
        color: black !important;
      }
    }
    &.active {
      a {
        color: #29aae1;
      }
    }
  }

  div:first-child {
    span:first-child {
      @media screen and (max-width: 767px){
        padding-top: 9px;
      }
    }


  }
  div:last-child {
    span:last-child {
      @media screen and (max-width: 767px){
        border-bottom: none;
        padding-bottom: 5px;
      }
    }
  }
}

.nav-category {
  @media screen and (max-width: 767px){
    position: relative;
  }
  &:after {
    @media screen and (max-width: 767px){
      font-size: 18px;
      display: inline-block;
      font-family: 'icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\ea41";
      display: inline-block;
      position: absolute;
      top: 11px;
      right: 0;
      transition: all ease-in-out 0.3s;
      transform: rotate(180deg);
    }
  }
  &.is-collapsed {
    &:after {
      transform: rotate(0deg);
      transition: all ease-in-out 0.3s;
      top: 12px;
      color: #29aae1;
    }

    .nav-sub-categories {
      @media screen and (min-width: 768px) and (max-width: 991px){
        display: flex;
        top: 43px;
        transition: all ease-in 0.3s;
      }

      @media screen and (max-width: 767px){
        display: block;
        transition: all ease-in 0.3s;
      }
    }
  }
}
.nav ul li.nav-category.active > * {
  a {
    color: #505052;
  }
  .active a {
    color: #29aae1;
  }
}

.nav ul li.nav-category.active {
  &:after {
    @media screen and (max-width: 767px){
      transform: rotate(0deg);
      top: 12px;
      color: #29aae1;
    }
  }

  .nav-sub-categories {
    @media screen and (max-width: 767px){
      display: block;
    }
  }
}

.nav-close {
  position: absolute;
  right: 0px;
  top: 14px;
  font-size: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;

  &.is-visible {
    opacity: 1;
    right: 20px;
    visibility: visible;
    transition: all ease-in-out 0.3s;
  }
}


@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.animated.delay-250s {
  -webkit-animation-delay: .25s;
  animation-delay: .25s;
}

.animated.quick {
  -webkit-animation-duration: 450ms;
  animation-duration: 450ms;
}