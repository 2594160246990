.gnotice {
  position: fixed;
  display: block;
  width: 100%;
  background: rgb(57, 57, 57);
  font-size: 14px;
  color: rgb(0, 0, 0);
  z-index: 2147000001;
  border: 0px solid rgb(255, 255, 255);
  bottom: 0px;
  left: 0px;

  a {
    color: #afe3f8;
    text-decoration: underline;
  }
}

.gnotice-accept {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 5px 12px;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.5s ease 0s;
    @media screen and (max-width: 767px){
      right: 10px;
      }
}
.gnotice-accept-svg{
  fill: white;
}
.gnotice-accept:hover .gnotice-accept-svg{
  fill: silver;
}

.gnotice-message {
  padding: 20px 120px 20px 120px;
  color: white;
  line-height: 1.75;

    @media screen and (max-width: 767px){
      padding: 20px 60px 20px 20px;
      }
}