.footer {
  margin-top: 4px;
  background-color: #EEF3F6;
  padding: 20px;
  position: relative;

  @media screen and (max-width: 767px){
    border-top: 1px solid #ccc;
    background-color: #FFF;
    transform: translate(0px);
    transition: translate ease-in 300ms;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 0px 12px;
    padding:12px 0px calc(env(safe-area-inset-bottom) + 12px);
    -webkit-transform: translateZ(0);
  }

  @media screen and (min-width: 768px){
    &:before {
      width: 100%;
      height: 4px;
      background-color: #d9d9d9;
      content: ' ';
      display: block;
      position: absolute;
      top: -8px;
      left: 0px;
      right: 0px;
    }
  }
}

.footer-links {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 767px){
      display: flex;
      justify-content: space-around;

    }
  }



  li {
    @media screen and (min-width: 768px){
      display: inline-block;
      font-size: 16px;
      position: relative;
      padding: 0 15px;
      margin-left: -5px;
      line-height: 0;
    }

    @media screen and (max-width: 991px){
      font-size: 14px;
    }

    @media screen and (max-width: 767px){
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      font-size: 10px;
      flex: 0 1 25%;
      text-align: center;
      padding: 0 6px;
    }
  }

  li:after {
    @media screen and (min-width: 768px){
      content: '';
      width: 1px;
      height: 100%;
      display: block;
      background-color: #302C26;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  li:last-child:after {
    @media screen and (min-width: 768px){
      display: none;
    }
  }

  li:first-child {
    @media screen and (min-width: 768px){
      margin-left: 0px;
    }
  }

  li a {
    @media screen and (min-width: 768px){
      line-height: normal;
    }

    @media screen and (max-width: 767px){
      font-size: 10px;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
    }
  }
  li a:hover, li a:active {
    @media screen and (max-width: 767px){
      text-decoration: none;
    }

  }
  li.active a {
    @media screen and (max-width: 767px){
      color: #29aae1;
    }
  }
  li.active .f-link:before {
    @media screen and (max-width: 767px){
      color: #29aae1;
    }
  }
}

.f-link {
  &:before {
    display: inline-block;
    font-size: 22px;
    margin-bottom: 6px;
    position: relative;
    color: #505052;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @media screen and (max-width: 767px){
    &--about:before {
      content: "\ea0c";
    }
    &--categories:before {
      content: "\e92e";
    }
    &--stores:before {
      content: "\e935";
      font-size: 20px;
      top: 1px;
    }
    &--home:before {
      content: "\e902";
    }
  }
}

.footer-copyright {
  margin-top: 15px;

  @media screen and (max-width: 991px){
    font-size: 14px;
  }

  @media screen and (max-width: 767px){
    display: none;
  }
}

.footer-privacy {
  position: relative;
  margin-left: 15px;
  padding-left: 16px;

  &:before {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    background-color: #302C26;
    position: absolute;
    left: 0px;
    top: 0;
  }
}
