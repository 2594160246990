.box {
  background-color: #EEF3F6;
  border: 1px solid #c3c3c5;
  margin-bottom: 15px;
  padding: 1px;

  &--left {
    @media screen and (min-width: 768px){
      border-top-left-radius: 10px;
    }

    .box-header{
      @media screen and (min-width: 768px) {
        border-top-left-radius: 10px;
      }
    }

  }

  &--right {    @media screen and (min-width: 768px) {
    border-top-right-radius: 10px;
  }
    .box-header{
      @media screen and (min-width: 768px) {
        border-top-right-radius: 10px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.box-header {
  background: #2a2a2a; /* Old browsers */
  background: -moz-linear-gradient(top, #545456 0%, #2a2a2a 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #545456 0%,#2a2a2a 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #545456 0%,#2a2a2a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#545456', endColorstr='#2a2a2a',GradientType=0 ); /* IE6-9 */
  border: 1px solid #2a2a2a;
  padding: 6px 5px 8px 20px;
}

.box-title {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  font-weight: normal;
}

.box-side-item {
  @media screen and (max-width: 767px){
    margin-right: 15px;
    -webkit-overflow-scrolling: touch;
  }
  @media screen and (min-width: 768px){
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
  img {
    @media screen and (max-width: 767px){
      max-width: 100%;
      width: 100%;
    }
  }
}

.box-list {
  list-style-type: none;
  margin: 0;
  padding: 4px 10px 4px 22px;
  border-top: 4px solid #d9d9d9;

  li {
    color: #000;
    border-bottom: 1px dotted #acaeaf;
    line-height: 20px;
    padding: 8px 0;
    font-size: 14px;
    word-break: break-all;
    text-indent: -3px;

    &:last-child {
      border-bottom: none;
    }

    &:before {
      font-size: 6px;
      display: inline-block;
      font-family: 'icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e900";
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      left: -6px;
    }
  }
}

.box-content-top {
  padding: 5px 5px 0 5px;
  border-top: 4px solid #d9d9d9;
}

.box-top-coupons {
  padding: 8px 5px 8px 5px;
  font-family: Serif;
  background: #DDE7ED; /* Old browsers */
  background: -moz-linear-gradient(top, #fefefe 0%, #dee7ee 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fefefe 0%,#dee7ee 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fefefe 0%,#dee7ee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#dee7ee',GradientType=0 ); /* IE6-9 */
  margin-bottom: 10px;
  position: relative;

  @media screen and (max-width: 767px){
    padding: 8px 15px;
  }

  &:after {
    border-bottom: 1px dotted #acaeaf;
    display: block;
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

.box-top-coupon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: #2A2A2B;
    text-decoration: underline;
    font-weight: bold;

    @media screen and (max-width: 767px){
      font-size: 18px;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .icon {
    font-size: 6px;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}

.box-top-label {
  color: #6D90AB;
  @media screen and (max-width: 767px){
    font-size: 18px;
  }
}

.box-side--coupons {
  padding: 0;
  -webkit-overflow-scrolling: touch;
  &.slick-initialized .slick-slide {
    margin-right: 10px;
  }
  &.slick-initialized .slick-slide:last-child {
  }
}
//.slick-list{padding:0 20% 0 0;}

.blue-header {
  border-top: 1px solid #5190d5;
  background: #1ebced; /* Old browsers */
  background: linear-gradient(to bottom, #1ebced 0%, #064383 100%);
  border-bottom: 4px solid #d9d9d9;
  margin-top: 15px;
}

.blue-header-title {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 5px 20px 8px 20px;
  @media screen and (max-width: 767px){
    padding: 7px 20px 9px 20px;
  }
}

.promo-box {
  border-bottom: 1px dashed #acaeaf;
  margin-bottom: 7px;
  display: flex;
  padding: 8px 25px 12px 4px;


  @media screen and (max-width: 767px){
    padding: 8px 15px 12px 15px;
  }
  &:last-child {
    margin-bottom: 15px;
  }

  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

    img {
      @media screen and (min-width: 768px){
        width: 150px;
      }
      @media screen and (max-width: 767px){
        width: 100px;
      }
    }
  }
}

.promo-box-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  h4 {
    font-size: 16px;
    margin-bottom: 0.25rem;
    line-height: 1.25em;
  }

  p {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .btn {
    align-self: flex-end;
  }
}

.top-coupons-container {
  margin-bottom: 20px;
  display: block;
  @media screen and (max-width: 767px){
    margin-left: -5px;
    margin-right: -5px;
    background-color: rgba(0,0,0,.03);
    border-top: .1rem solid rgba(0,0,0,.05);
    border-bottom: .1rem solid rgba(0,0,0,.05);
    position: relative;
    overflow: hidden;
  }
  @media screen and (max-width: 767px){
    &:after, &:before {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 20px;
      text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
      transition: all ease-in 300ms;
      font-family: 'slick';
      font-size: 40px;
      line-height: 1;
      color: white;
      -webkit-font-smoothing: antialiased;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
    }
    &:before {
      left: -17px;
      content: '←';
    }
    &:after {
      right: -17px;
      content: '→';
    }

    &.scrollable {
      &:after {
        opacity: .75;
        visibility: visible;
      }
    }
    &.scrolling {
      &:after, &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
    &.scrolled-right {
      &:before {
        opacity: .75;
        visibility: visible;
      }
      &:after {
        opacity: 0;
        visibility: hidden;
      }
    }
}
.top-coupons-list {
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px;


  @media screen and (max-width: 767px){
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;

  }
    @media screen and (min-width: 768px){
      width: 100%;
      }
    }

}
.top-coupons-box {
  min-width: 220px;
  margin: 0 5px;
  flex: 0 1 33%;
  border: 1px solid #e3e3e3;
  padding: 2px;
  display: flex;
  color: #6D90AB;

}
.top-coupons-box:first-child {
  @media screen and (min-width: 768px){
    margin-left: 10px;
  }
}
.top-coupons-box:last-child {
  @media screen and (min-width: 768px){
    margin-right: 10px;

  }
  @media screen and (max-width: 767px){
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      width: 20px;
      height: 1px;
    }
  }
}
.top-coupons-box-inner span {
  display: block;
}
.top-coupons-box-inner {
  background: #DDE7ED; /* Old browsers */
  background: linear-gradient(to bottom, #ffffff 0%, #f9fafc 30%, #eef3f7 50%, #dee7ee 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px 8px 8px 9px;

  span {
    display: block;
    font-size: 0.75em;
    padding-left: 8px;
  }
}

.top-coupons-button {
  text-align: center;
  margin: 10px 0;
}


.top-coupons-detail {
  border-top: 1px dotted #6D90AB;
}

.top-coupons-content {
  padding-bottom: 20px;
  font-size: 0.875em;

  p {
    margin-top: 6px;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

.top-coupons-title {
  padding: 0 0 0 6px;

  &:before {
    font-size: 6px;
    font-family: icons!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e900";
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    left: -6px;
  }

}

