.header {
  @media screen and (max-width: $md){
    transform: translateX(0px);
    transition: all ease-in 300ms;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }

  &.stickit {
    @media screen and (max-width: 767px) {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0px;
      background-color: #fff;
      transition: all ease-in 300ms;
      z-index: 14;
      box-shadow: 0px 4px 8px -3px rgba(0,0,0,0.3);
      right: 0;
    }
  }

  &.nav-up {
    @media screen and (max-width: 767px) {
      top:-130px;
    }
  }
}

.header-nav {
  @media screen and (min-width: 768px) {
    border: 1px solid #5291d2;
    border-radius: 9px 9px 0 0;
    background: #1ebced; /* Old browsers */
    background: linear-gradient(to bottom, #1ebced 0%,#064383 100%);
    position: relative;
  }

  &:after {
    @media screen and (min-width: 992px) {
      z-index: 1;
      position: absolute;
      content: "";
      bottom: 47px;
      right: auto;
      height: 8px;
      left: 20px;
      width: 240px;
      top: 0px;
      max-width: 300px;
      background: #000;
      box-shadow: 0 15px 10px #000;
      transform: rotate(3deg);

    }
  }
}

.header-stickit {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.header-logo {
  @media screen and (min-width: 992px) {
    margin: 38px 0 0 25px;
  }
  @media screen and (min-width: 768px) {
    display: block;
    width: 200px;
    height: 70px;
    float: left;
    margin: 38px 0 0 25px;
    z-index: 10;
    position: relative;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin: 20px 0 0 10px;
  }
  @media screen and (max-width: 767px) {
    display: inline-block;
    margin: 4px 10px 15px 65px;

    img {
      height: 40px;
    }
  }
}

.header-top {
  @media screen and (max-width: 991px) {
    position: relative;
  }

  @media screen and (max-width: 767px) {
    background-color: white;
  }
}

.header-banners {
  float: right;
  padding: 13px 90px 12px 15px;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 13px 0px 12px 15px;
  }
}

.header-logo-shadow {
  @media screen and (min-width: 992px) {
    width: 270px;
    height: 50px;
    background: #FFF;
    z-index: 2;
    position: absolute;
    top: -29px;
    left: -5px;
  }

  @media screen and (max-width: 767px){
    display: none;
  }
}

.header-breadcrumbs {
  @media screen and (max-width: 767px){
    display: none;
    background-color: rgba(0,0,0,.03);
    border-top: .1rem solid rgba(0,0,0,.05);
    border-bottom: .1rem solid rgba(0,0,0,.05);
    box-shadow: 0 0.1rem 0 rgba(0,0,0,.03) inset, 0 0.1rem 0 rgba(255,255,255,.3);
  }
}


.mobile-menu-toggle {
  @media screen and (max-width: 767px){
    position: absolute;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);

    width: 30px;
    height: 30px;
    padding-top: 3px;
    text-align: center;
    font-size: 24px;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
      content: "\e901";
    }
  }
}

.mobile-search-toggle {
  @media screen and (max-width: 767px){
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);

    width: 30px;
    height: 30px;
    padding-top: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
      content: "\e986";
    }
  }
  @media screen and (min-width: 768px){
    display: none;
  }
}




