.btn {
  border: 1px solid #ababab;
  border-radius: 6px;
  display: inline-block;
  height: 28px;
  line-height: 25px;
  text-transform: lowercase;
  background: #eceded; /* Old browsers */
  background: linear-gradient(to bottom, #eceded 0%,#c7c9c9 100%);
  font-size: 12px;
  padding: 0px 14px;
  font-weight: bold;
  position: relative;

  &:hover {
    text-decoration: none;
    border: 1px solid #ccc;
    transition: all ease-in-out 150ms;
    color: #6d90ab;
  }

  &:disabled {
    opacity: 0.6;
  }
  &--blue {
    border: 1px solid #5291d2;
    background: #2ea4e5; /* Old browsers */
    background: linear-gradient(to bottom, #83e2f7 0%,#2ea4e5 100%);
    &:hover {
      text-decoration: none;
      border: 1px solid #2ea4e5;
      color: #fff;
    }
  }

}