// content
.content {
  padding-bottom: 40px;
  @media screen and (max-width: $md){
    transform: translateX(0px);
    transition: all ease-in 300ms;
    padding-bottom: 75px;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    width: 100%;
  }
}

// content --
.content{
  &--store_detail {
    @media screen and (max-width: 767px){
      padding-bottom: 95px;
    }

    .content-right {
      display: none;
      @media screen and (min-width: 992px){
        display: none;
      }

      @media screen and (max-width: 767px){
        display: none;
      }
    }
  }
  &--contact, &--about, &--privacy {
    .content-right {
      @media screen and (max-width: 767px){
        display: none;
      }
    }
  }
  &--category, &--all-stores {
    @media screen and (max-width: 767px){
      padding-bottom: 95px;
    }
  }
}

.content-title {
  display: block;
  border-bottom: 2px solid #6D90AB;
  font-size: 1.625em;
  letter-spacing: -1px;
  color: #6D90AB;
  font-weight: normal;
  padding: 0 20px;
  margin: 10px 0 25px;
}
.content-text {
  padding: 10px 20px 30px;
  @media screen and (max-width: 767px){
    padding: 10px 15px 30px;
  }

  h3 {
    color: #589ac1;
    font-weight: bold;
  }

  a {
    color: #589ac1;
    font-weight: bold;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

.content-left {
  width: 220px;
  //z-index: 1;
  //position: relative;
  @media screen and (min-width: 992px){
    flex: 0 0 220px;
    margin-right: 10px;
  }
  @media screen and (max-width: 991px){
    display: none;
  }
}
.content-main {

  @media screen and (min-width: 768px){
    flex: 1 1 auto;
  }
  @media screen and (max-width: $md){
    padding-top: 15px;
  }
  &--index {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      max-width: calc(100% - 200px);
    }
    @media screen and (max-width: $md){
      width: 100%;
    }
  }
  &--stores {
    width: 100%;
    @media screen and (min-width: 992px){

    }
    @media screen and (max-width: 767px){
      padding: 0 5px;
    }
  }
  &--category {
    width: 100%;
    @media screen and (min-width: 992px){

    }
    @media screen and (max-width: 767px){
      padding: 0 5px;
    }
  }
}
.content-right {
  width: 190px;
  @media screen and (min-width: 768px){
    flex: 0 0 190px;
    margin-left: 10px;
  }

  @media screen and (max-width: 767px){
    width: 100%;
    margin-bottom: 10px;
  }
}

.index-main-banners {

}
.index-banner {
  img {
    width: 100%;
  }

  &:nth-child(2) {
    @media screen and (min-width: 992px) {
      margin-top: 10px;
    }
  }

}
.index-main-text {
  //border-bottom: 1px dashed #acaeaf;
  margin-top: 20px;
  padding-bottom: 25px;

  @media screen and (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  p {
    font-size: 14px;
  }

  img {
    width: 100%;
  }
}
.index-promo {
  margin-top: 10px;
}
.details-banner {
  width: 100%;
  padding: 15px 0 5px;
  text-align: center;
  @media screen and (max-width: 767px){
    padding: 15px 10px 5px;
  }
  img {
    max-width: 100%;
  }
}

.details-title {
  display: block;
  border-bottom: 2px solid #6D90AB;
  font-size: 1.625em;
  letter-spacing: -1px;
  color: #6D90AB;
  font-weight: normal;
  padding: 0 8px 0 8px;
  margin: 15px 0 25px;
  @media screen and (max-width: 767px){
    margin: 15px -5px 14px;
    padding: 0 8px 0 15px;
    font-size: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}
.details-info {
  border: 1px solid #cdcdcd;
  margin: 10px 0 30px;
  @media screen and (max-width: 767px){
    margin: 10px 0 30px;
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
    border-bottom: 2px solid #6D90AB;
    padding: 8px;
    color: #6D90AB;
    margin-bottom: 0;
  }
}
.details-info-content {
  display: flex;
  padding: 15px 20px 15px 20px;
  @media screen and (max-width: 767px){
    padding: 12px 8px;
  }
}
.details-info-links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 20px;
  @media screen and (max-width: 767px){
    margin-right: 8px;
  }

  .btn {
    align-self: center;
    margin-bottom: 2px;
  }
}
.details-info-logo {
  margin-bottom: 10px;
  display: inline-block;

  @media screen and (max-width: 767px){
    img {
      width: 120px;
    }
  }
}
.details-info-text {
  color: #68707b;
  font-size: 0.75em;
  line-height: 1.25em;
  padding-top: 2px;
}

.detail-coupon {
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  margin-bottom: 30px;
  @media screen and (max-width: 767px){
    margin-bottom: 20px;
  }
}
.detail-coupon-header {
  background-color: #dde7ed;
  padding: 5px 10px 6px 14px;
  border-radius: 10px 10px 0 0;
}
.detail-coupon-title {
  color: #010101;
  font-weight: 700;
  font-size: 14px;
  margin: 0;

  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}
.detail-coupon-content {
  color: #68707b;
  font-size: 0.875em;
  line-height: 1.25em;
  padding: 12px 20px 12px 14px;
  @media screen and (max-width: 767px){
    padding: 12px 10px;
  }
}
.detail-coupon-opt {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.detail-coupon-code {
  margin-right: 10px;
  font-size: 0.75em;
}
.detail-coupon-button {
  display: flex;
  align-items: flex-end;
  margin-right: 8px;
}
.detail-coupon-code {
  span {
    display: block;
  }
}



.box-side--coupons .slick-list {

}
.store-list {
  border: 1px solid #c3c3c1;
  table {
    width: 100%;
    tr:nth-child(odd) {
      background: #EEF3F6;
    }
    td {
      padding: 20px 0;
      border-bottom: 1px solid #c3c3c1;
      width: 33.33%;
    }

    .name {
      width: 160px;
      padding: 0 0 0 0px;
      font-size: 1.3em;
      text-align: center;
      @media screen and (max-width: 767px){
        padding: 0 0 0 15px;
      }

      img {
        max-width:150px;
        width: 100%;
      }
    }
    .description {
      text-align: center;
      text-transform: uppercase;
    }
    .opis {
      text-align: center;
      font-weight:bold;
      font-size:11px;
      line-height:14px;
      color:#686868;
    }
    .opis a{
      color:#686868;
      text-decoration: underline;
    }
    .opis a:hover{
      text-decoration: none;
    }
    .button {
      text-align: center;
    }
  }
}