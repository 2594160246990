input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

.page-crumbs {
  line-height: 35px;
  font-size: 12px;
  color: #68707b;
  margin: 10px 0px 10px 0;
}

.page-link {
  margin: 0 4px 0 0;
  display: inline-block;
  text-align: center;
  border: 1px solid #cdcdcd;
  @media screen and (min-width: 768px){
    border: 1px solid #cdcdcd;
  }
  @media screen and (max-width: 767px){
    font-size: 14px;
    flex: 1 1 auto;
    max-width: 45px;
  }

  a {
    height: 28px;
    line-height:24px;
    display: block;
    text-decoration: none;
    padding: 2px 4px;
    min-width: 28px;
    @media screen and (max-width: 767px){
      font-size: 14px;
      padding: 2px 2px;
    }
    @media screen and (min-width: 992px){
      height: 24px;
      line-height: 20px;
      min-width: 24px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.page-current {
  line-height:24px;
  padding: 2px 4px;
  min-width: 28px;
  border: none;
}
.page-number {
  background: #eeeeee;
}

.page-label {
  a {
    padding: 2px 6px;
    @media screen and (max-width: 767px){
      padding: 2px 2px;
    }
  }
}

.btn.page-go {
  height: 30px;
  line-height: 27px;
  vertical-align: bottom;
  padding: 0 8px;
  border-radius: 5px;
}

.page-number:hover {
  background: #ffffff;
}

.page-NumberForm {
  display: inline-block;
  line-height: 30px;
  flex: 0 0 auto;
  margin: 0 5px 0 0;

  @media screen and (min-width: 768px){
    display: none;
  }

  input {
    width: 35px;
    height: 30px;
    border: 1px solid #29aae1;
    text-align: center;
    padding: 0 2px;
    line-height: 20px;
    border-radius: 0;
    box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.3);;
  }
}


.page-small {
  text-align: right;
  @media screen and (max-width: 767px){
    margin-right: 15px;
    .page-NumberForm {
      display: none;
    }
    .page-label {
      padding: 0 4px;
    }
  }
}

.page-large {
  @media screen and (max-width: 767px){

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0px 15px 0;

    .page-number {
      display: none;
    }
    .page-current {
      display: none;
    }
  }
  @media screen and (min-width: 768px){
    text-align: right;
  }
}