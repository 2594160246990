body {
  font-family: Tahoma, Arial, helvetica, sans-serif;
  color:#383838;
  padding: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  text-decoration: underline;
  color: #333;
}

.container {
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: $lg-min) {
    max-width: 960px;
  }
  @media (min-width: $md-min) and (max-width: $lg){
    max-width: 720px;
  }

  @media (min-width: $sm-min) and (max-width: $md){
    max-width: 560px;
  }
}


.menu-on {
  overflow-y: hidden;
  width: 100%;
  position: fixed;
  height: calc(100% - 20px);
  -webkit-overflow-scrolling: touch;

  .header, .footer, .content {
    transform: translateX(275px);
    transition: all ease-in 300ms;

    @media (min-width: $sm-min) and (max-width: $md){
      transform: translateX(45%);
    }

  }
}



