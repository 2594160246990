@font-face {
  font-family: 'icons';
  src:  url('fonts/icons.eot?6vv67');
  src:  url('fonts/icons.eot?6vv67#iefix') format('embedded-opentype'),
  url('fonts/icons.ttf?6vv67') format('truetype'),
  url('fonts/icons.woff?6vv67') format('woff'),
  url('fonts/icons.svg?6vv67#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e903";
}
.icon-hamburger:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e900";
}
.icon-home3:before {
  content: "\e902";
}
.icon-stack:before {
  content: "\e92e";
}
.icon-price-tag:before {
  content: "\e935";
}
.icon-phone:before {
  content: "\e942";
}
.icon-search:before {
  content: "\e986";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-down:before {
  content: "\ea43";
}
