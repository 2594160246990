.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-center {
  text-align: center;
}

@media screen and (min-width: $lg-min){
  .hidden-lg-up {
    display: none !important;
  }
}
@media screen and (min-width: $md-min){
  .hidden-md-up {
    display: none !important;
  }
}
@media screen and (max-width: $md){
  .hidden-md-down {
    display: none !important;
  }
}
@media screen and (max-width: $lg){
  .hidden-lg-down {
    display: none !important;
  }
}

@media screen and (min-width: $md-min) and (max-width: $lg){
  .hidden-md {
    display: none !important;
  }
}

.hidden-mobile-load {
  @media screen and (max-width: 767px){
    display: none;
  }
}

@media screen and (max-width: $md){
  /* phones */
}
@media screen and (min-width: $md-min) and (max-width: $lg){
  /* portrait phones and tablets */
}
@media screen and (max-width: $lg){
  /* less then desktop */
}
@media screen and (min-width: $lg-min){
  /*  desktop */
}